export const ready = (callback) => {
  if (document.readyState !== "loading") {
    callback()
  } else {
    const eventCallback = () => {
      document.removeEventListener("DOMContentLoaded", eventCallback)
      callback()
    }

    document.addEventListener("DOMContentLoaded", eventCallback)
  }
}
